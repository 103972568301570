import MiniStatistics from '../../main/account/application/components/MiniStatistics';
import {
  MdOutlineEnergySavingsLeaf,
  MdOutlineWaterDrop,
  MdOutlineCo2,
} from 'react-icons/md';
import { FaRecycle } from 'react-icons/fa6';

import ReactECharts from 'echarts-for-react';
import RadialBar from 'components/charts/RadialBar';
import { radialBarDataAudit } from 'variables/charts';
import { radialBarOptionsAudit } from 'variables/charts';

import Balance from './components/Balance';
import DailyTraffic from './components/DailyTraffic';
import MostVisited from './components/MostVisited';
import OverallRevenue from './components/OverallRevenue';
import ProfitEstimation from './components/ProfitEstimation';
import ProjectStatus from './components/ProjectStatus';
import YourCard from './components/YourCard';
import YourTransfers from './components/YourTransfers';

import tableDataMostVisited from './variables/tableDataMostVisited';

const Dashboard = () => {
  const options = {
    series: [
      {
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        center: ['50%', '75%'],
        radius: '90%',
        min: 0,
        max: 1,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 6,
            color: [
              [0.25, '#FF6E76'],
              [0.5, '#FDDD60'],
              [0.75, '#58D9F9'],
              [1, '#7CFFB2'],
            ],
          },
        },
        pointer: {
          icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
          length: '12%',
          width: 20,
          offsetCenter: [0, '-60%'],
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          length: 12,
          lineStyle: {
            color: 'auto',
            width: 2,
          },
        },
        splitLine: {
          length: 20,
          lineStyle: {
            color: 'auto',
            width: 5,
          },
        },
        axisLabel: {
          color: '#464646',
          fontSize: 15,
          distance: -45,
          rotate: 'tangential',
          formatter: function (value: number) {
            if (value === 0.875) {
              return 'Excellent';
            } else if (value === 0.625) {
              return 'Good';
            } else if (value === 0.375) {
              return 'Warning';
            } else if (value === 0.125) {
              return 'Alert';
            }
            return '';
          },
        },
        title: {
          offsetCenter: [0, '-10%'],
          fontSize: 20,
        },
        detail: {
          fontSize: 45,
          offsetCenter: [0, '-35%'],
          valueAnimation: true,
          formatter: function (value: number) {
            return Math.round(value * 100) + '';
          },
          color: 'inherit',
        },
        data: [
          {
            value: 0.7,
            name: 'Grade Rating',
          },
        ],
      },
    ],
  };
  return (
    <>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-2">
        <div
          className={`flex flex-col justify-center rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none`}
        >
          <ReactECharts
            className="w-full"
            option={options}
            notMerge={true}
            lazyUpdate={true}
            // theme={'theme_name'}
            // onChartReady={this.onChartReadyCallback}
            // onEvents={EventsDict}
            // opts={}
          />
          <div className="dark:text-white">Your company is in good hands.</div>
        </div>
        <div
          className={`flex flex-col justify-center rounded-[20px] bg-clip-border p-6 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none`}
        >
          <RadialBar
            classname="w-full"
            chartData={radialBarDataAudit}
            chartOptions={radialBarOptionsAudit}
          />
          <div className="w-full dark:text-white">
            Your company is ready to generate a report. Please click download
            for generate a nice report. You need wait around 1~2 hours.
            <button className="linear mt-[34px] flex w-full items-center justify-center rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 3xl:w-5/6">
              Generate Report
            </button>
          </div>
        </div>
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <div>
          <MiniStatistics
            icon={
              <MdOutlineEnergySavingsLeaf className="text-4xl text-green-500" />
            }
            title="Energy consumption"
            value="3.204 kWh"
            growth={'-20%'}
            growthColor="text-green-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            bgColor="bg-lightPrimary dark:!bg-navy-700"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdOutlineWaterDrop className="text-4xl text-blue-500" />}
            title="Water usage"
            value="1.249 m³"
            bgColor={'bg-lightPrimary dark:!bg-navy-700'}
            growth={'+12%'}
            growthColor="text-red-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdOutlineCo2 className="text-4xl text-pink-600" />}
            title="Emissions"
            value="1.920 CO2"
            bgColor={'bg-lightPrimary dark:!bg-navy-700'}
            growth={'-16%'}
            growthColor="text-green-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<FaRecycle className="text-4xl text-green-500" />}
            title="Recycling rates"
            value="27%"
            bgColor={'bg-lightPrimary dark:bg-navy-700'}
            growth={'+27%'}
            growthColor="text-green-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
        <div className="h-full w-full rounded-[20px]">
          {/* left side */}
          <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
            {/* overall & Balance */}
            <div className="mb-5 grid grid-cols-6 gap-5">
              <div className="col-span-6 h-full w-full rounded-xl 3xl:col-span-4">
                <OverallRevenue />
              </div>
              <div className="col-span-6 w-full 3xl:col-span-2">
                <Balance />
              </div>
            </div>
            {/* Daily Traffic and.. */}
            <div className="mt-5 grid w-full grid-cols-6 gap-5">
              <div className="col-span-6 md:col-span-3 3xl:col-span-2">
                <DailyTraffic />
              </div>
              <div className="col-span-6 md:col-span-3 3xl:col-span-2">
                <ProjectStatus />
              </div>
              <div className="col-span-6 3xl:col-span-2">
                <ProfitEstimation />
              </div>
            </div>
            {/* Your Transfers & tables */}
            <div className="mt-5 grid w-full grid-cols-6 gap-5">
              <div className="col-span-6 3xl:col-span-2">
                <YourTransfers />
              </div>
              <div className="col-span-6 3xl:col-span-4">
                <MostVisited tableData={tableDataMostVisited} />
              </div>
            </div>
          </div>
        </div>

        {/* line */}
        <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

        {/* right section */}
        <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
          <YourCard />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
